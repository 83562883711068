import { useParams } from 'react-router-dom';
import UserRecordSection from '../user_record_section';
import {
  postResource,
  useFetchResource,
} from '../../../../utils/api_request_helpers';
import {
  Message,
  MessageThread,
} from '../../../../types/coaching/message_thread';
import { CoachingApiEndpoints } from '../../../api_endpoints';
import CoachingButton from '../../coaching_button';
import { standardDisplayTime } from '../../../../utils/time_helpers';
import { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import FormButton from '../../forms/form_button';

export default function ThreadDetails() {
  const params = useParams<{ userId: string; threadId: string }>();
  const threadId = params.threadId!;

  const [showEditor, setShowEditor] = useState(false);

  const [loadingThread, , thread, refetchThread] =
    useFetchResource<MessageThread>(
      CoachingApiEndpoints.userMessageThread(params.userId!, threadId),
    );

  useEffect(() => {
    const markThreadAsRead = async () => {
      await postResource(
        CoachingApiEndpoints.userMessageThreadMarkRead(
          params.userId!,
          threadId,
        ),
        {},
      );
    };

    markThreadAsRead();
  }, [threadId, params.userId]);

  const sortedMessages = () => {
    if (!thread?.messages) {
      return [] as Message[];
    }

    return thread.messages.slice().reverse();
  };

  const closeThread = async () => {
    const confirmed = window.confirm(
      'Are you sure you want to archive this thread?',
    );

    if (!confirmed) {
      return;
    }

    const [status] = await postResource(
      CoachingApiEndpoints.userMessageThreadArchive(params.userId!, threadId),
      {},
    );

    if (status !== 200) {
      alert(
        'Unable to archive thread. Please try again or contact engineering',
      );
      return;
    }

    refetchThread();
  };

  if (!threadId) {
    return null;
  }

  return (
    <UserRecordSection
      title={thread?.subject || 'No subject'}
      loading={loadingThread}>
      <div className="pb-4">
        <div className="flex flex-col mb-3">
          <div className="px-4 py-2 bg-blue-100 flex-grow rounded-md">
            <div className="space-y-8 mt-4">
              {sortedMessages().map((message) => (
                <MessageRecord key={message.id} message={message} />
              ))}
            </div>
          </div>
          <div className="bg-white">
            <div className="mt-2">
              {showEditor && (
                <NewMessageForm
                  threadId={threadId}
                  userId={params.userId!}
                  onCancel={() => {
                    setShowEditor(false);
                  }}
                  onSuccess={() => {
                    refetchThread();
                    setShowEditor(false);
                  }}
                />
              )}
            </div>
            {!showEditor && !thread?.read_only && (
              <div className="flex space-x-4 mt-4">
                <CoachingButton
                  title="Reply"
                  onClick={() => {
                    setShowEditor(true);
                  }}
                />
                <CoachingButton
                  title="Archive Thread"
                  variant="alert"
                  onClick={() => {
                    closeThread();
                  }}
                />
              </div>
            )}
            {!showEditor && thread?.read_only && (
              <div className="italic text-gray-500">
                This thread is read only
              </div>
            )}
          </div>
        </div>
      </div>
    </UserRecordSection>
  );
}

function NewMessageForm({
  threadId,
  userId,
  ...props
}: {
  threadId: string;
  userId: string;
  onCancel: () => void;
  onSuccess: () => void;
}) {
  const initialValues = {
    body: '',
  };

  const submitToServer = async (values: typeof initialValues) => {
    const [status] = await postResource(
      CoachingApiEndpoints.userMessageThreadMessages(userId, threadId),
      values,
    );
    if (status !== 201) {
      alert('Unable to send message. Please try again or contact engineering');
      return;
    }

    props.onSuccess();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitToServer}>
      {({ handleSubmit, resetForm }) => (
        <form onSubmit={handleSubmit}>
          <Field
            as="textarea"
            name="body"
            className="block w-full bg-gray-300 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            placeholder="Write your message here"
          />
          <div className="flex space-x-4 mt-2">
            <FormButton title="Send" />
            <CoachingButton
              title="Cancel"
              variant="alert"
              onClick={() => {
                resetForm();
                props.onCancel();
              }}
            />
          </div>
        </form>
      )}
    </Formik>
  );
}

function MessageRecord({ message }: { message: Message }) {
  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center">
          <SenderIcon message={message} />

          <div className="ml-2">{standardDisplayTime(message.created_at)}</div>
        </div>
        {!message.from_user && (
          <div className="flex">
            {message.read_at && (
              <div className="mr-1 text-gray-600">Message seen </div>
            )}
            {message.read_at ? <ReadIcon /> : <UnReadIcon />}
          </div>
        )}
      </div>

      <div className="mt-2 text-gray-600 whitespace-pre-line">
        {message.body}
      </div>
    </div>
  );
}

function SenderIcon({ message }: { message: Message }) {
  return (
    <span
      className={
        'inline-flex h-8 w-8 items-center justify-center rounded-full ' +
        (message.from_user ? 'bg-gray-500' : 'bg-orange-400')
      }>
      <span className="text-sm font-medium leading-none text-white">
        {message.from_user ? 'U' : 'W'}
      </span>
    </span>
  );
}

function ReadIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1213 14.6213C14.6839 14.0587 15 13.2956 15 12.5C15 11.7044 14.6839 10.9413 14.1213 10.3787C13.5587 9.81607 12.7956 9.5 12 9.5C11.2044 9.5 10.4413 9.81607 9.87868 10.3787C9.31607 10.9413 9 11.7044 9 12.5C9 13.2956 9.31607 14.0587 9.87868 14.6213C10.4413 15.1839 11.2044 15.5 12 15.5C12.7956 15.5 13.5587 15.1839 14.1213 14.6213Z"
        stroke="#52525B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.45801 12.5C3.73201 8.443 7.52301 5.5 12 5.5C16.478 5.5 20.268 8.443 21.542 12.5C20.268 16.557 16.478 19.5 12 19.5C7.52301 19.5 3.73201 16.557 2.45801 12.5Z"
        stroke="#52525B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function UnReadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none">
      <path
        d="M13.875 19.325C13.2569 19.4419 12.6291 19.5005 12 19.5C7.52203 19.5 3.73203 16.557 2.45703 12.5C2.8003 11.4081 3.32902 10.3835 4.02003 9.471M9.87803 10.379C10.4407 9.81634 11.2038 9.50025 11.9995 9.50025C12.7952 9.50025 13.5584 9.81634 14.121 10.379C14.6837 10.9417 14.9998 11.7048 14.9998 12.5005C14.9998 13.2962 14.6837 14.0593 14.121 14.622M9.87803 10.379L14.121 14.622M9.87803 10.379L14.12 14.62M14.121 14.622L17.412 17.912M9.88003 10.38L6.59003 7.09M6.59003 7.09L3.00003 3.5M6.59003 7.09C8.20239 6.04957 10.0811 5.4974 12 5.5C16.478 5.5 20.268 8.443 21.543 12.5C20.8391 14.7305 19.3774 16.6446 17.411 17.911M6.59003 7.09L17.411 17.911M17.411 17.911L21 21.5"
        stroke="#52525B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
