import { Formik, Field, Form, FormikHelpers } from 'formik';
import { useState } from 'react';
import { postResource } from '../../utils/api_request_helpers';
import { InternalToolsApiEndpoints } from '../../coaching/api_endpoints';

export default function AiPromptTestPage() {
  const initialValues = {
    prompt: '',
  };

  const [aiResponse, setAiResponse] = useState('');

  const submitToServer = async (
    values: { prompt: string },
    formik: FormikHelpers<any>,
  ) => {
    formik.setSubmitting(true);
    const [status, result] = await postResource(
      InternalToolsApiEndpoints.aiPromptTests,
      values,
    );

    if (status !== 200) {
      alert('Unable to submit prompt, please try again');
      formik.setSubmitting(false);
      return;
    }

    setAiResponse(result.ai_response);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">AI Prompt Test</h1>
      <Formik initialValues={initialValues} onSubmit={submitToServer}>
        {({ handleSubmit, isSubmitting }) => (
          <Form className="space-y-4 mb-4" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="prompt"
                className="block text-sm font-medium text-gray-700">
                Prompt
              </label>
              <Field
                as="textarea"
                id="prompt"
                name="prompt"
                placeholder="Enter your prompt"
                className="mt-1 block w-full p-2 border rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 min-h-[60vh] overflow-y-auto resize-none"
              />
            </div>
            <div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </Form>
        )}
      </Formik>

      {aiResponse && (
        <div>
          <h2 className="text-xl font-bold mb-2">AI Response</h2>
          <div className="p-4 border rounded-md bg-gray-50 whitespace-pre-line">
            {aiResponse}
          </div>
        </div>
      )}
    </div>
  );
}
